<template>
    <Fragment>
        <v-btn 
            color="primary" 
            class="flex-grow-1 flex-shrink-1"
            :disabled="oficialSuspendido"
            @click.stop="modalSubirDocumentoAbierta = true"
        >
            <v-icon class="mr-1" color="white" style="font-size: 20px;">mdi-file-upload-outline</v-icon>
            Subir documento
        </v-btn>
        <v-dialog v-model="modalSubirDocumentoAbierta" max-width="700px">
            <div style="background-color: #FFFFFF; padding: 0 1em 0; position: relative;">
                <div class="pt-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4 py-4"
                        @click.stop="cerrarModalCreacion" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <div class="px-1 pb-4 mt-4">
                    <v-form 
                        ref="form" 
                        @submit.prevent="validarEnvioFormulario"
                    >
                        <v-text-field 
                            v-model="form.nombre"
                            label="Nombre"
                            placeholder="Nombre del documento"
                            outlined
                            :error-messages="nombreErrors"
                            :disabled="guardadoArchivo.isLoading || oficialSuspendido"
                            @change="$v.form.nombre.$touch()"
                            @blur="$v.form.nombre.$touch()"
                        />
                        <v-file-input
                            v-model="form.adjunto"
                            label="Adjunto"
                            placeholder="Seleccione un PDF"
                            outlined
                            light
                            accept="application/pdf"
                            :error-messages="adjuntoErrors"
                            :disabled="guardadoArchivo.isLoading || oficialSuspendido"
                            @blur="$v.form.adjunto.$touch()"
                        />
                        <div class="d-flex align-center justify-center flex-wrap-reverse mt-4" style="gap: 16px;">
                            <v-btn 
                                color="secondary" 
                                class="flex-grow-1 flex-shrink-1"
                                :disabled="guardadoArchivo.isLoading"
                                @click.stop="cerrarModalCreacion"
                            >
                                Cancelar
                            </v-btn>
                            <v-btn 
                                color="primary" 
                                type="submit"
                                class="flex-grow-1 flex-shrink-1"
                                :disabled="guardadoArchivo.isLoading || oficialSuspendido"
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </div>
        </v-dialog>
        
        <ConfirmationModalComponent 
            :is-open="confirmacionAbierta"
            :is-loading="guardadoArchivo.isLoading"
            description="¿Desea guardar el documento?"
            @cancel="confirmacionAbierta = false"
            @confirm="guardarArchivo"
        />
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import { required, maxLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation';
import { convertToFormData } from '@/utils/data';
import { mapActions, mapState } from 'vuex';
import { Fragment } from 'vue-fragment';

const dataInicialForm = {
    nombre: null,
    adjunto: null,
}

export default {
    name: 'CargarDocumentoApartado',
    components: { Fragment, ConfirmationModalComponent },
    props: {
        apartado: { type: Object },
    },
    validations: () => {
        return {
            form: {
                nombre: { required, maxLength: maxLength(250) } ,
                adjunto: { required },
            },
        };
    },
    data: () => ({
        modalSubirDocumentoAbierta: false,
        form: { ...dataInicialForm },
        confirmacionAbierta: false,
        guardadoArchivo: createLoadable(null),
    }),
    computed: {
        ...mapState('panelAdministracionOdc', ['oficialSuspendido']),
        nombreErrors() {
            return new Validator(this.$v.form.nombre).detect().getResult();
        },
        adjuntoErrors() {
            return new Validator(this.$v.form.adjunto).detect().getResult();
        },
    },
    methods: {
        ...mapActions('panelAdministracionOdc', ['cargarApartados']),
        limpiarFormulario() {
            this.$v.form.$reset();
            this.$refs.form.reset();
            this.form = { ...dataInicialForm };
        },
        cerrarModalCreacion() {
            this.modalSubirDocumentoAbierta = false;
            this.limpiarFormulario();
        },
        validarEnvioFormulario() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.modalSubirDocumentoAbierta = false;
            this.confirmacionAbierta = true;
        },
        async guardarArchivo() {
            toggleLoadable(this.guardadoArchivo);
            const payload = {
                ...this.form,
                id_tipo_documento: this.apartado.id,
            };

            const formData = convertToFormData(payload);
            const { data } = await this.services.VerificacionDocumentosOdc.guardarDocumentoOficialCumplimiento(formData);
            this.confirmacionAbierta = false;
            this.limpiarFormulario();
            setLoadableResponse(this.guardadoArchivo, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.cargarApartados(this.idOficialCumplimiento);
                return;
            }
            
            if (data?.error.code === 'RELOAD_REQUIRED') this.cargarApartados(this.idOficialCumplimiento);
        },
    },
}
</script>