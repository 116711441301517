<template>
    <Fragment>
        <h5 class="font-weight-bold text-uppercase text-subtitle-1 mt-6">Nombramiento</h5>
        <v-divider />
        <v-btn 
            v-if="!esAdministradorDINAC"
            :disabled="oficialSuspendido"
            small 
            color="primary" 
            class="mt-2 mt-sm-3 float-sm-right"
            :loading="actualizacionNombramiento.isLoading"
            @click.stop="modalActualizacionAbierto = true"
        >
            <v-icon class="mr-1" color="white" style="font-size: 18px;">
                mdi-update
            </v-icon>
            Actualizar
        </v-btn>
        <dl class="mt-3 mt-sm-2">
            <dt class="font-weight-bold">Fecha nombramiento:</dt>
            <dd>{{ fechaNombramiento }}</dd>

            <dt class="font-weight-bold mt-3">Fecha finalización:</dt>
            <dd>{{ fechaFinalizacion }}</dd>

            <dt class="font-weight-bold mt-3">Tipo:</dt>
            <dd>{{ tipoNombramiento }}</dd>
        </dl>

        <!-- Modal para actualizar nombramiento -->
        <v-dialog v-model="modalActualizacionAbierto" max-width="700" persistent>
            <div style="background-color: #FFFFFF; padding: 1em; position: relative;">
                <div style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4 py-4"
                        @click.stop="modalActualizacionAbierto = false" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <div class="px-1 mt-4">
                    <v-form ref="form" @submit.prevent="confirmarActualizacionNombramiento">
                        <v-menu transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <v-text-field 
                                        v-model="fecha_nombramientoValue"
                                        label="Fecha de nombramiento" 
                                        placeholder="Seleccione una fecha"
                                        light 
                                        outlined 
                                        readonly
                                        :error-messages="fecha_nombramientoErrors"
                                        @change="$v.form.fecha_nombramiento.$touch()"
                                        @blur="$v.form.fecha_nombramiento.$touch()"
                                    />
                                </div>
                            </template>
                            <div @click.stop>
                                <vc-date-picker 
                                    v-model="form.fecha_nombramiento"
                                    mode="date"
                                    locale="es"                 
                                />
                            </div>
                        </v-menu>

                        <v-menu transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <v-text-field 
                                        label="Fecha de finalización" 
                                        placeholder="Seleccione una fecha"
                                        :disabled="!form.fecha_nombramiento"
                                        :value="fecha_FinalizacionValue"
                                        light 
                                        outlined 
                                        readonly
                                    >
                                        <template v-slot:append>
                                            <v-icon 
                                                v-if="fecha_FinalizacionValue" 
                                                @click.stop="form.fecha_finalizacion = null"
                                            >
                                                mdi-close
                                            </v-icon>
                                        </template>
                                    </v-text-field>
                                </div>
                            </template>
                            <div @click.stop>
                                <vc-date-picker 
                                    v-model="form.fecha_finalizacion"
                                    mode="date"
                                    locale="es"
                                    :min-date="fechaMinimaFinalizacion"
                                />
                            </div>
                        </v-menu>

                        <div class="d-flex justify-center align-center" style="gap: 16px;">
                            <v-btn 
                                color="secondary" 
                                class="flex-grow-1 flex-shrink-1"
                                @click.stop="modalActualizacionAbierto = false"
                            >
                                Cancelar
                            </v-btn>
                            <v-btn 
                                color="primary" 
                                class="flex-grow-1 flex-shrink-1"
                                type="submit"
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </div>
        </v-dialog>
        <ConfirmationModalComponent 
            :is-open="modalConfirmacionAbierto"
            description="¿Desea continuar con la acción?"
            :is-loading="actualizacionNombramiento.isLoading"
            @cancel="modalConfirmacionAbierto = false"
            @confirm="actualizarNombramiento"
        />
    </Fragment>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import { required } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation';
import { Fragment } from 'vue-fragment';
import { mapState } from 'vuex';
import moment from 'moment';

const dataInicialForm = {
    fecha_nombramiento: null,
    fecha_finalizacion: null,
};

export default {
    name: 'NombramientoOdc',
    emits: ['on-update'],
    components: { Fragment, ConfirmationModalComponent },
    validations: {
        form: {
            fecha_nombramiento: { required },
            fecha_finalizacion: {},
        },
    },
    props: {
        nombramiento: { type: Object },
    },
    data: () => ({
        modalActualizacionAbierto: false,
        modalConfirmacionAbierto: false,
        actualizacionNombramiento: createLoadable(null),
        // Formulario
        form: { ...dataInicialForm },
        formCache: { ...dataInicialForm },
    }),
    computed: {
        ...mapState('panelAdministracionOdc', ['oficialSuspendido']),
        esAdministradorDINAC() {
            return this.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
        },
        // Formulario
        fechaMinimaFinalizacion() {
            const fechaNombramiento = this.form.fecha_nombramiento;
            if (!fechaNombramiento) return;

            return moment(fechaNombramiento).add(1, 'days').toDate();
        },
        fecha_nombramientoValue() {
            if (!this.form.fecha_nombramiento) return '';

            return moment(this.form.fecha_nombramiento).format("DD-MM-YYYY");
        },
        fecha_FinalizacionValue() {
            if (!this.form.fecha_finalizacion) return '';

            return moment(this.form.fecha_finalizacion).format("DD-MM-YYYY");
        },
        fecha_nombramientoErrors() {
            return new Validator(this.$v.form.fecha_nombramiento).detect().getResult();
        },
        // UI
        fechaNombramiento() {
            return this.formatearFecha(this.nombramiento?.fecha_nombramiento);
        },
        fechaFinalizacion() {
            return this.formatearFecha(this.nombramiento?.fecha_finalizacion);
        },
        tipoNombramiento() {
            if (!this.nombramiento) return '-';

            return this.nombramiento.fecha_finalizacion ? 'Temporal' : 'Permanente';
        },
    },
    methods: {
        formatearFecha(valor) {
            return valor ? moment(valor).format('DD-MM-YYYY') : '-';
        },
        llenarCampos() {
            this.form = {
                fecha_nombramiento: this.nombramiento?.fecha_nombramiento ? moment(this.nombramiento.fecha_nombramiento).startOf('day').toDate() : null,
                fecha_finalizacion: this.nombramiento?.fecha_finalizacion ? moment(this.nombramiento.fecha_finalizacion).startOf('day').toDate() : null,
            };
        },
        limpiarFormulario() {
            if (!this.nombramiento) {
                this.$v.form.$reset();
                this.$refs.form.reset();
            }
            
            this.llenarCampos();
        },
        confirmarActualizacionNombramiento() {
            this.$v.form.$touch();

            if (this.$v.form.$invalid) return;

            this.formCache = { ...this.form };
            this.modalConfirmacionAbierto = true;
            this.modalActualizacionAbierto = false;
        },
        async actualizarNombramiento() {
            this.modalConfirmacionAbierto = false;
            const payload = { ...this.formCache };
            toggleLoadable(this.actualizacionNombramiento);
            const { data } = await this.services.VerificacionDocumentosOdc.crearNombramiento(payload);
            setLoadableResponse(this.actualizacionNombramiento, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.$emit('on-update');
        },
    },
    watch: {
        modalActualizacionAbierto(value) {
            if (!value) this.limpiarFormulario();
        },
        nombramiento: {
            handler() {
                this.llenarCampos();
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>