<template>
    <v-dialog v-model="internalOpen" max-width="700" persistent>
        <div style="background-color: #FFFFFF; padding: 1em; position: relative;">
            <div style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1 mt-4">
                <v-form ref="form" @submit.prevent="manejarEnvioFormulario">
                    <v-file-input
                        v-model="form.adjunto"
                        label="Adjunto"
                        placeholder="Seleccione un PDF"
                        outlined
                        light
                        accept="application/pdf"
                    />

                    <v-textarea
                        v-model="form.descripcion"
                        label="Descripción"
                        placeholder="Descripción"
                        outlined
                        clearable
                        no-resize
                        rows="3"
                        :error-messages="descripcionErrors"
                        @change="$v.form.descripcion.$touch()"
                        @blur="$v.form.descripcion.$touch()"
                    />

                    <v-checkbox
                        v-if="esAdministradorDINAC"
                        v-model="form.es_observacion"
                        class="mt-0 pt-0" 
                        label="Es observación"
                        light
                    />

                    <div class="d-flex justify-center align-center flex-wrap" style="gap: 16px">
                        <v-btn color="secondary" class="flex-grow-1 flex-shrink-1" @click.stop="cerrarModal">
                            Cancelar
                        </v-btn>
                        <v-btn color="primary" class="flex-grow-1 flex-shrink-1" type="submit">
                            Guardar
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { Fragment } from 'vue-fragment';
import { required, maxLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation';
import { ConfirmationModalComponent } from '@/components/utils';
import { isNil } from 'lodash';

const informacionInicialForm = {
    adjunto: null,
    descripcion: null,
    es_observacion: false,
}

export default {
    name: 'ModalCrearInteraccion',
    emits: ['on-visibility-change', 'on-save'],
    components: { Fragment, ConfirmationModalComponent },
    validations: {
        form: {
            descripcion: { required, maxLength: maxLength(500) }
        },
    },
    props: {
        isOpen: { type: Boolean },
    },
    data: () => ({
        internalOpen: false,
        form: { ...informacionInicialForm },
        confirmacionAbierta: false,
    }),
    computed: {
        descripcionErrors() {
            return new Validator(this.$v.form.descripcion).detect().getResult();
        },
        esAdministradorDINAC() {
            return this.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
        },
    },
    methods: {
        limpiarFormulario() {
            this.$v.form.$reset();
            this.$refs.form.reset();
        },
        cerrarModal() {
            this.limpiarFormulario();
            this.internalOpen = false;
        },
        manejarEnvioFormulario() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.$emit('on-save', { ...this.form });
            this.cerrarModal();
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>