<template>
    <Fragment>
        <v-btn
            :color="esCTA ? 'primary' : 'secondary'" 
            :disabled="oficialSuspendido"
            class="flex-grow-1 flex-shrink-1" 
            @click.stop="modalCrearInteraccionAbierto = true"
        >
            <v-icon class="mr-1" color="white" style="font-size: 20px;">mdi-forum-plus</v-icon>
            Crear interacción
        </v-btn>
        <v-btn 
            color="secondary" 
            class="flex-grow-1 flex-shrink-1" 
            @click.stop="modalHistorialInteraccionesAbierto = true"
        >
            <v-icon class="mr-1" color="white" style="font-size: 20px;">mdi-eye</v-icon>
            Interacciones
        </v-btn>
        <!-- Modal historial interacciones -->
        <v-dialog v-model="modalHistorialInteraccionesAbierto" max-width="900">
            <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative;">
                <div class="pt-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4 py-4"
                        @click.stop="modalHistorialInteraccionesAbierto = false" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <div class="px-1 d-flex flex-column align-center">
                    <div v-if="interaccionesRecientes.isLoading" class="text-center mt-4">
                        <v-progress-circular 
                            indeterminate 
                            class="mt-2"
                            color="primary" 
                            :size="25" 
                            :width="3" 
                        />
                        <p class="py-0 my-0 mt-2 font-weight-bold">Cargando interacciones recientes...</p>
                    </div>
                    <div 
                        :class="`mt-${!bitacoras.length ? 4 : 0} mb-${!bitacoras.length ? 2 : 0}`" 
                        style="overflow: auto; width: 100%"
                    >
                        <EmptyComponent :empty="!bitacoras.length" colored>
                            <template v-slot:empty-content>
                                <div class="py-8">
                                    <v-icon x-large>mdi-table-eye-off</v-icon>
                                    <p class="mb-0 mt-2 font-weight-bold text-body-1">No existen interacciones para este apartado</p>
                                </div>
                            </template>

                            <app-time-line :items="bitacoras" :showActions="false">
                                <template v-slot:item="{ item }">
                                    <p class="mb-0">
                                        <b>{{ formatDate(item.created_at) }}
                                            <v-tooltip v-if="item.es_observacion" top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-on="on" v-bind="attrs" style="font-size: 20px;">mdi-eye</v-icon>
                                                </template>
                                                <span>Observación</span>
                                            </v-tooltip>
                                            -
                                        </b>
                                        {{ item.descripcion }}
                                        <v-icon
                                            v-if="item.adjunto" icon 
                                            @click.stop="visualizarAdjunto(item)" 
                                            :disabled="visualizacionAdjunto.isLoading"
                                            style="vertical-align: sub;"
                                        >
                                            mdi-file-pdf-box
                                        </v-icon>
                                    </p>
                                </template>
                            </app-time-line>
                        </EmptyComponent>
                        <div v-intersect="debouncedIntersectionHandler" class="text-center"></div>
                    </div>
                    <div v-if="interacciones.isLoading" class="text-center mt-4">
                        <v-progress-circular 
                            indeterminate 
                            class="mt-2"
                            color="primary" 
                            :size="25" 
                            :width="3" 
                        />
                        <p class="py-0 my-0 mt-2 font-weight-bold">Cargando interacciones...</p>
                    </div>
                </div>
            </div>
        </v-dialog>
        <PdfModal 
            filename="adjunto-interaccion"
            :is-open="modalVerAdjuntoAbierta"
            :source-loadable="visualizacionAdjunto"
            @on-visibility-change="cerrarModalVerAdjunto"
        />

        <!-- Crear interacciones -->
        <ModalCrearInteraccion 
            :is-open="modalCrearInteraccionAbierto"
            @on-visibility-change="cerrarModalCrearInteraccion"
            @on-save="confirmarcreacionInteraccion"
        />
        <ConfirmationModalComponent
            :is-open="confirmacionCreacionAbierta"
            description="¿Desea crear una nueva interacción para este apartado documental?"
            :is-loading="creacionInteraccion.isLoading"
            @cancel="confirmacionCreacionAbierta = false"
            @confirm="crearInteraccion"
        /> 
    </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment';
import { debounce } from 'lodash';
import { 
    createPageable,
    createLoadable,
    togglePageable,
    toggleLoadable, 
    setPageableResponse, 
    setLoadableResponse,
    isResponseSuccesful,
} from '@/utils/loadable';
import ModalCrearInteraccion from './ModalCrearInteraccion.vue';
import { ConfirmationModalComponent, EmptyComponent } from '@/components/utils';
import { formatDate } from '@/utils/datetime';
import { convertToFormData, removePropsFromObjectIfFalsy } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
    name: 'InteraccionesDocumentosOdc',
    components: { Fragment, PdfModal, ModalCrearInteraccion, ConfirmationModalComponent, EmptyComponent },
    props: {
        apartado: { type: Object },
        idOficialCumplimiento: { type: String },
        esCTA: { type: Boolean },
    },
    data: () => ({
        modalHistorialInteraccionesAbierto: false,
        interacciones: createPageable([], 30),
        interaccionesRecientes: createLoadable(null),
        paginacion: {
            page: 1,
            per_page: 30,
        },
        // Visualizar adjunto
        modalVerAdjuntoAbierta: false,
        visualizacionAdjunto: createLoadable(null),
        // Creacion interaccion
        informacionCreacion: null,
        modalCrearInteraccionAbierto: false,
        confirmacionCreacionAbierta: false,
        creacionInteraccion: createLoadable(null),
    }),
    computed: {
        ...mapState("panelAdministracionOdc", ['interaccionesEnCache', 'oficialSuspendido']),
        bitacoras() {
            return this.interacciones.data.map((interaccion) => ({ 
                ...interaccion, 
                color: interaccion.es_administrador_dinac ? 'primary' : '#2364aa', 
            }));
        },
        interaccionesCacheadas() {
            return this.interaccionesEnCache.get(this.apartado.id);
        },
        esAdministradorDINAC() {
            return this.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
        },
    },
    methods: {
        ...mapActions('panelAdministracionOdc', ['cargarApartados']),
        ...mapMutations('panelAdministracionOdc', ['guardarInteraccionesEnCache']),
        formatDate,
        handleIntersection(entries, observer, isIntersecting) {
            if (!isIntersecting) return;

            this.cargarSiguientesInteracciones();
        },
        cerrarModalVerAdjunto(visible) {
            this.modalVerAdjuntoAbierta = visible;
        },
        cerrarModalCrearInteraccion(visible) {
            this.modalCrearInteraccionAbierto = visible;
        },
        confirmarcreacionInteraccion(data) {
            this.informacionCreacion = data;
            this.confirmacionCreacionAbierta = true;
        },
        // Data
        async visualizarAdjunto(interaccion) {
            this.modalVerAdjuntoAbierta = true;
            togglePageable(this.visualizacionAdjunto);
            const { data } = await this.services.VerificacionDocumentosOdc.visualizarDocumentoInteraccion(interaccion.id);
            setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
        },
        async cargarInteracciones() {
            const filtros = { pagination: true, ...this.paginacion };

            togglePageable(this.interacciones);
            const { data, headers } = await this.services.VerificacionDocumentosOdc.cargarInteraccionesApartadoDocumental(
                this.apartado.id, 
                filtros,
                this.idOficialCumplimiento,
            );
            setPageableResponse(this.interacciones, data, headers);
        },
        async cargarSiguientesInteracciones() {
            if (this.interacciones.isLoading) return;
            const { page, per_page, total_rows } = this.interacciones.pagination;

            const registrosCargados = Math.ceil(page * per_page);

            if (!(registrosCargados < total_rows)) return;

            const interaccionesCargadasLength = this.interacciones.data.length;
            const ultimaInteraccionCargada = this.interacciones.data[interaccionesCargadasLength - 1];

            if (!ultimaInteraccionCargada) return;

            const filtros = {
                pagination: true,
                page: 1,
                per_page: this.paginacion.per_page,
                antes_de: ultimaInteraccionCargada.id,
            };

            togglePageable(this.interacciones);
            const { data, headers } = await this.services.VerificacionDocumentosOdc.cargarInteraccionesApartadoDocumental(
                this.apartado.id,
                filtros,
                this.idOficialCumplimiento,
            );
            setPageableResponse(this.interacciones, data, headers, { skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                const interaccionesActuales = this.interacciones.data;
                const interaccionesActualizadas = interaccionesActuales.concat(data.data);
                this.interacciones.data = interaccionesActualizadas;
            }
        },
        async refrescarInteraccionesRecientes() {
            const primeraInteraccionCargada = this.interacciones.data[0];

            if (!primeraInteraccionCargada && this.interacciones.error) return;
            
            const filtros = { 
                pagination: false,
                despues_de: primeraInteraccionCargada?.id ?? null,
            };

            toggleLoadable(this.interaccionesRecientes);
            const { data } = await this.services.VerificacionDocumentosOdc.cargarInteraccionesApartadoDocumental(
                this.apartado.id,
                filtros,
                this.idOficialCumplimiento,
            );
            setLoadableResponse(this.interaccionesRecientes, data, { skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                const interaccionesActuales = this.interacciones.data;
                const interaccionesActualizadas = data.data.concat(interaccionesActuales);
                this.interacciones.data = interaccionesActualizadas;
            }
        },
        async crearInteraccion() {
            const formData = convertToFormData(removePropsFromObjectIfFalsy(this.informacionCreacion, ['es_observacion']));
            toggleLoadable(this.creacionInteraccion);
            const { data } = await this.services.VerificacionDocumentosOdc.crearInteraccionSobreDocumentoOdc(this.apartado.id, this.idOficialCumplimiento, formData);
            this.confirmacionCreacionAbierta = false;
            setLoadableResponse(this.creacionInteraccion, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                await this.refrescarInteraccionesRecientes();
                this.cargarApartados(this.idOficialCumplimiento);
                return;
            }

            if (data?.error.code === 'RELOAD_REQUIRED') this.cargarApartados(this.idOficialCumplimiento);
        },
    },
    created() {
        this.debouncedIntersectionHandler = debounce(this.handleIntersection, 500);
        this.cargarInteracciones();
    },
    beforeDestroy() {
        this.guardarInteraccionesEnCache({ key: this.apartado.id, interacciones: this.interacciones.data });
    },
    watch: {
        interaccionesCacheadas: {
            handler(value) {
                if (!value) return;

                this.interacciones.data = value;
            },
            immediate: true,
        },
    },
}
</script>