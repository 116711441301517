<template>
    <Fragment>
        <NavButton v-if="mostrarBotonNavegacion" />
        <v-alert v-if="oficialSuspendido" type="info" class="my-4" dense>
            Su usuario se encuentra suspendido, abajo encontrará el motivo del suspenso.
        </v-alert>
        <v-row class="mt-4">
            <v-col cols="12" lg="6">
                <v-card class="px-4 pb-4" :loading="informacion.isLoading">
                    <v-card-title class="px-0 py-0 pt-4">
                        <div class="d-flex align-center" style="gap: 8px;">
                            <v-icon color="primary" large>mdi-account-circle</v-icon>
                            <h4 class="text-uppercase flex-grow-1">Oficial de cumplimiento</h4>
                            <template v-if="informacion.data">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-on="on" v-bind="attrs" v-if="informacion.data.verificacion?.verificado" color="success">mdi-check-decagram</v-icon>
                                        <v-icon v-on="on" v-bind="attrs" v-else color="orange accent-2">mdi-alert-decagram</v-icon>
                                    </template>
                                    <span>{{ informacion.data.verificacion?.verificado ? 'Verificado' : 'Pendiente de verificación' }}</span>
                                </v-tooltip>
                            </template>
                        </div>
                    </v-card-title>
                    <v-card-text class="px-0 py-0">
                        <RetryDataLoading :loadable="informacion" :retry-handler="cargarInformacion" class="mt-4">
                            <v-skeleton-loader
                                :loading="informacion.isLoading"
                                boilerplate
                                type="article, article"
                            >
                            <EmptyComponent
                                text="No hay información por mostrar"
                                colored
                                :empty="!informacion.data && informacion.isDirty && !informacion.isLoading"
                            >
                                <template v-slot:empty-content>
                                    <v-icon x-large>mdi-account-search-outline</v-icon>
                                    <p class="mb-0 mt-2 font-weight-bold text-body-1">No hay información para mostrar</p>
                                </template>
                                <template v-if="informacion.data">
                                    <h5 class="mt-4 font-weight-bold text-uppercase text-subtitle-1">información general</h5>
                                    <v-divider />
                                    <dl class="mt-1">
                                        <dt class="font-weight-bold">Nombre:</dt>
                                        <dd>{{ informacion.data.nombre_completo }}</dd>
            
                                        <dt class="font-weight-bold mt-3">Correo electrónico:</dt>
                                        <dd>{{ informacion.data.usuario.email }}</dd>

                                        <dt class="font-weight-bold mt-3">Perfil:</dt>
                                        <dd>{{ informacion.data.perfil.nombre }}</dd>
            
                                        <dt class="font-weight-bold mt-3">Institución:</dt>
                                        <dd>{{ informacion.data.institucion.codigo }} - {{ informacion.data.institucion.nombre }}</dd>

                                        <dt class="font-weight-bold mt-3">Estado:</dt>
                                        <dd>
                                            <v-chip v-if="informacion.data.usuario.es_oficial_de_cumplimiento_suspendido" color="#00B6F5" text-color="#FFF !important" label>
                                                <v-icon class="mr-1" color="#FFF !important" style="font-size: 20px;">mdi-account-cancel</v-icon>
                                                Suspendido
                                            </v-chip>
                                            <v-chip v-else color="#252B58" text-color="#FFF !important" label>
                                                <v-icon class="mr-1" color="#FFF !important" style="font-size: 20px;">mdi-account-check</v-icon>
                                                Habilitado
                                            </v-chip>
                                            <template v-if="informacion.data.usuario.es_oficial_de_cumplimiento_suspendido">
                                                <v-btn
                                                    :disabled="!informacion.data.suspension.adjunto"
                                                    :loading="descargaJustificacion.isLoading"
                                                    @click.stop="descargarJustificacionSuspension" 
                                                    class="ml-1" 
                                                    icon
                                                >
                                                    <v-icon>mdi-download</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    :disabled="!informacion.data.suspension.adjunto"
                                                    :loading="visualizacionJustificacion.isLoading"
                                                    @click.stop="visualizarJustificacionSuspension" 
                                                    class="ml-1" 
                                                    icon
                                                >
                                                    <v-icon>mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                        </dd>
                                    </dl>
                                    <NombramientoOdc :nombramiento="informacion.data.nombramiento" @on-update="cargarInformacion" />
                                </template>
                            </EmptyComponent>
                            </v-skeleton-loader>

                        </RetryDataLoading>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6">
                <v-card class="px-4 py-4">
                    <v-card-title class="px-0 py-0">
                        <div class="d-flex align-center" style="gap: 8px;">
                            <v-icon color="primary" large>mdi-text-box</v-icon>
                            <h4 class="text-uppercase flex-grow-1">Documentos</h4>
                        </div>
                    </v-card-title>
                    <v-card-text class="px-0 py-0">
                        <RetryDataLoading :loadable="apartados" :retry-handler="cargarArbolDocumentos" class="mt-4">
                            <v-skeleton-loader
                                v-if="apartados.isLoading"
                                boilerplate
                                type="article, article"
                            />
                            <EmptyComponent v-else colored :empty="apartados.data.length === 0">
                                <template v-slot:empty-content>
                                    <v-icon x-large>mdi-file-document-alert</v-icon>
                                    <p class="mb-0 mt-2 font-weight-bold text-body-1">No hay nada por mostrar</p>
                                </template>
                                <ArbolDocumentalOdc 
                                    :apartado="{ sub_apartados: apartados.data}" 
                                    :id-oficial-cumplimiento="idOficialCumplimiento"
                                />
                            </EmptyComponent>
                        </RetryDataLoading>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <PdfModal 
            :is-open="modalVerJustificacionSuspensionAbierta"
            :filename="`justificacion-suspension(${informacion.data?.nombre_completo})`"
            :source-loadable="visualizacionJustificacion"
            @on-visibility-change="cerrarVisualizacionJustificacionSuspension"
        />
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse } from '@/utils/loadable';
import { EmptyComponent, NavButton, RetryDataLoading } from '@/components/utils';
import ArbolDocumentalOdc from './ArbolDocumentalOdc.vue';
import NombramientoOdc from './NombramientoOdc.vue';
import PdfModal from '@/components/PdfModal.vue';
import { mapState, mapActions } from 'vuex';
import { Fragment } from 'vue-fragment';

export default {
    name: 'DocumentosOficialCumplimiento',
    components: { Fragment, NavButton, EmptyComponent, RetryDataLoading, ArbolDocumentalOdc, NombramientoOdc, PdfModal },
    props: {
        idOficialCumplimiento: { type: String },
        mostrarBotonNavegacion: { type: Boolean, default: false },
    },
    data: () => ({
        panels: [],
        // Suspension ODC
        modalVerJustificacionSuspensionAbierta: false,
        visualizacionJustificacion: createLoadable(null),
        descargaJustificacion: createLoadable(null),
    }),
    computed: {
        ...mapState('panelAdministracionOdc', ['apartados', 'informacion', 'oficialSuspendido']),
        esAdministradorDINAC() {
            return this.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
        },
        arbolApartados() {
            return this.apartados.data;        
        },
    },
    methods: {
        ...mapActions('panelAdministracionOdc', ['cargarApartados', 'cargarInformacionOdc']),
        verificarArbolCompletamenteVerificado(apartadoVerificar) {
            const subApartados = apartadoVerificar.sub_apartados;

            if (subApartados.length > 0) {
                return subApartados.every(this.verificarArbolCompletamenteVerificado);
            }

            return apartadoVerificar.requerido_en_verificacion_global ? Boolean(apartadoVerificar.verificacion?.estado.id === 3) : true;
        },
        cargarInformacion() {
            this.cargarInformacionOdc(this.idOficialCumplimiento);
        },
        // Suspension ODC
        cerrarVisualizacionJustificacionSuspension(visible) {
            this.modalVerJustificacionSuspensionAbierta = visible;
        },
        async visualizarJustificacionSuspension() {
            this.modalVerJustificacionSuspensionAbierta = true;
            toggleLoadable(this.visualizacionJustificacion);
            const { data } = await this.services.VerificacionDocumentosOdc.visualizarJustificacionSuspension(this.informacion.data.usuario.id);
            setLoadableResponse(this.visualizacionJustificacion, data, { isFile: true });
        },
        async descargarJustificacionSuspension() {
            toggleLoadable(this.descargaJustificacion);
            const { data } = await this.services.VerificacionDocumentosOdc.visualizarJustificacionSuspension(this.informacion.data.usuario.id);
            setLoadableResponse(this.descargaJustificacion, data, { isFile: true, skipOnSuccess: true });

            if (data && !('error' in data)) {
                const file = new File([data], `justificacion_suspension(${this.informacion.data.nombre_completo}).pdf`,
                    { type: "application/octet-stream" },
                );
    
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(file);
                link.download = file.name;
                link.click();
            }
        },
        cargarArbolDocumentos() {
            this.cargarApartados(this.idOficialCumplimiento)
        },
    },
    mounted() {
        this.cargarArbolDocumentos();
        this.cargarInformacion();
    },
    watch: {
        arbolApartados(value) {
            if (!this.informacion.data) return;

            const oficialVerificado = !!this.informacion.data?.verificacion?.verificado;
            const arbolVerificado = this.verificarArbolCompletamenteVerificado({ sub_apartados: value });

            if (oficialVerificado !== arbolVerificado) this.cargarInformacion();
        },
    },
}
</script>
<style scoped>
:deep(.v-expansion-panel-content__wrap) {
    padding: 0 !important;
}
</style>