<template>
    <Fragment>
        <v-expansion-panels v-if="tieneSubApartados" v-model="openKeys">
            <v-expansion-panel v-for="sub_apartado in apartado.sub_apartados" :key="sub_apartado.id">
                <v-expansion-panel-header>
                    <div class="d-flex align-center justify-space-between" style="gap: 16px;">
                        <p class="font-weight-medium pb-0 mb-0" style="gap: 4px; word-break: break-word;">
                            {{ sub_apartado.titulo }}
                        </p>

                        <IndicadoresApartadoDocumental :apartado="sub_apartado" />
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider />
                    <div class="px-4 py-4">
                        <DocumentoVerificacion :apartado="sub_apartado" :id-oficial-cumplimiento="idOficialCumplimiento" />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <template v-else class="px-4 py-4">
            <div class="d-flex align-center flex-wrap mb-4 acciones-arbol-documental" style="gap: 16px;">
                <v-btn 
                    v-if="esAdministradorDINAC && apartado.documento && apartado.verificacion?.estado.id !== 3" 
                    color="primary" 
                    class="flex-grow-1 flex-shrink-1" 
                    @click.stop="confirmarVerificacion"
                >
                    <v-icon class="mr-1" color="white" style="font-size: 20px;">mdi-check-all</v-icon>
                    Verificar
                </v-btn>
                <CargarDocumentoApartado v-if="!esAdministradorDINAC" :apartado="apartado" />
                <InteraccionesDocumentosOdc 
                    :apartado="apartado" 
                    :id-oficial-cumplimiento="idOficialCumplimiento" 
                    :esCTA="esAdministradorDINAC && !(apartado.documento && apartado.verificacion?.estado.id !== 3)"
                />
                <HistorialDocumentosApartado :apartado="apartado" :id-oficial-cumplimiento="idOficialCumplimiento" />
            </div>

            <div class="mt-8">
                <template v-if="apartado.documento">
                    <div class="d-flex align-center" style="gap: 8px;">
                        <v-icon>mdi-paperclip</v-icon>
                        <span class="font-weight-bold text-uppercase text-subtitle-2">Documento</span>
                    </div>
                    <v-divider class="mt-2 mb-4" />
                    <DataTableComponent 
                        :headers="headers"
                        :items="[apartado.documento]"
                        :tiene_paginacion="false"
                    >
                        <template v-slot:item.nombre="{ item }">
                            <span class="d-block text-center">{{ item.nombre }}</span>
                        </template>
                        <template v-slot:item.acciones>
                            <div class="d-flex align-center justify-center" style="gap: 16px;">
                                <v-btn color="secondary" icon @click.stop="visualizarAdjunto">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn 
                                    v-if="!esAdministradorDINAC" 
                                    color="secondary" 
                                    :disabled="oficialSuspendido"
                                    icon @click.stop="confirmacionEliminacion = true"
                                >
                                    <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </DataTableComponent>
                </template>
                <EmptyComponent v-else empty colored>
                    <template v-slot:empty-content>
                        <v-icon x-large>mdi-file-document-alert</v-icon>
                        <p class="mb-0 mt-2 font-weight-bold text-body-1">No se ha cargado ningún documento</p>
                    </template>
                </EmptyComponent>
            </div>
            <ConfirmationModalComponent 
                :is-open="confirmacionEliminacion"
                :is-loading="eliminadoArchivo.isLoading"
                description="¿Desea eliminar el documento?"
                @cancel="confirmacionEliminacion = false"
                @confirm="eliminarArchivo"
            />
            <ConfirmationModalComponent 
                :is-open="confirmacionVerificacion"
                :is-loading="verificacionApartado.isLoading"
                description="¿Desea marcar este apartado como verificado?"
                @cancel="confirmacionVerificacion = false"
                @confirm="verificarApartado"
            />
            <PdfModal
                :isOpen="modalVisualizacionAdjuntoAbierta" 
                :source-loadable="visualizacionAdjunto"
                @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
                :filename="apartado.documento?.nombre ?? 'documento'"
            />
        </template>
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import IndicadoresApartadoDocumental from './IndicadoresApartadoDocumental.vue';
import { ConfirmationModalComponent, EmptyComponent } from '@/components/utils';
import HistorialDocumentosApartado from './HistorialDocumentosApartado.vue';
import InteraccionesDocumentosOdc from './InteraccionesDocumentosOdc.vue';
import CargarDocumentoApartado from './CargarDocumentoApartado.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import PdfModal from '@/components/PdfModal.vue';
import { mapState, mapActions } from 'vuex';
import { Fragment } from 'vue-fragment';

export default {
    name: 'DocumentoVerificacion',
    emits: ['on-cambio-documento'],
    components: { 
        IndicadoresApartadoDocumental,
        HistorialDocumentosApartado,
        ConfirmationModalComponent,
        InteraccionesDocumentosOdc,
        CargarDocumentoApartado,
        DataTableComponent,
        EmptyComponent,
        PdfModal,
        Fragment,
    },
    props: {
        apartado: { type: Object },
        idOficialCumplimiento: { type: String },
    },
    data: () => ({
        // Visualizacion adjunto
        modalVisualizacionAdjuntoAbierta: false,
        visualizacionAdjunto: createLoadable(null),
        // Eliminado de documento
        confirmacionEliminacion: false,
        eliminadoArchivo: createLoadable(null),
        // Verificacion apartado
        verificacionApartado: createLoadable(null),
        confirmacionVerificacion: false,
    }),
    computed: {
        ...mapState('panelAdministracionOdc', ['openKeysArbolApartado', 'oficialSuspendido']),
        esAdministradorDINAC() {
            return this.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
        },
        tieneSubApartados() {
            return this.apartado.sub_apartados?.length > 0;
        },
        openKeys: {
            get() {
                return this.openKeysArbolApartado.get(this.apartado.id);
            },
            set(value) {
                this.openKeysArbolApartado.set(this.apartado.id, value);
            },
        },
        headers() {
            return [
                { text: 'Nombre', value: 'nombre', align: 'center', sortable: false },
                { text: 'Acción', value: 'acciones', align: 'center', sortable: false },
            ]
        },
    },
    methods: {
        ...mapActions('panelAdministracionOdc', ['cargarApartados']),
        // UI
        manejarVisibilidadModalVisualizacionAdjunto(visible) {
            this.modalVisualizacionAdjuntoAbierta = visible;
        },
        confirmarVerificacion() {
            this.confirmacionVerificacion = true;
        },
        // Data
        async visualizarAdjunto() {
            this.modalVisualizacionAdjuntoAbierta = true;
            toggleLoadable(this.visualizacionAdjunto);
            const { data } = await this.services.VerificacionDocumentosOdc.visualizarDocumentoOficialCumplimiento(this.apartado.documento.id);
            setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
        },
        async eliminarArchivo() {
            toggleLoadable(this.eliminadoArchivo);
            const { data } = await this.services.VerificacionDocumentosOdc.eliminarDocumentoOficialCumplimiento(this.apartado.documento.id);
            this.confirmacionEliminacion = false;
            setLoadableResponse(this.eliminadoArchivo, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data) || data?.error.code === 'RELOAD_REQUIRED') this.cargarApartados(this.idOficialCumplimiento);
        },
        async verificarApartado() {
            toggleLoadable(this.verificacionApartado); 
            const { data } = await this.services.VerificacionDocumentosOdc.marcarDocumentoComoVerificado(this.apartado.id, this.idOficialCumplimiento);
            this.confirmacionVerificacion = false;
            setLoadableResponse(this.verificacionApartado, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data) || data?.error.code === 'RELOAD_REQUIRED') this.cargarApartados(this.idOficialCumplimiento);
        },
    },
}
</script>
<style scoped>
:deep(.acciones-arbol-documental .v-btn) {
    max-width: 100%;
    height: unset !important;
    padding: 12px 16px !important;
}

:deep(.acciones-arbol-documental .v-btn__content) {
    max-width: 100%;
    word-break: break-word !important;
    white-space: normal;
    display: block !important;
}
</style>