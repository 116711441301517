<template>
    <Fragment>
        <v-btn 
            color="secondary" 
            class="flex-grow-1 flex-shrink-1"
            :loading="documentos.isLoading"
            @click.stop="cargarHistorialDocumentos"
        >
            <v-icon class="mr-1" color="white" style="font-size: 22px;">mdi-history</v-icon>
            Documentos
        </v-btn>

        <v-dialog v-model="modalHistorialDocumentosAbierta" max-width="800px" persistent>
            <div style="background-color: #FFFFFF; padding: 1em; position: relative;">
                <div style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <div class="px-1 mt-4 mb-2">
                    <DataTableComponent 
                        dense
                        no-gutters
                        :headers="headers"
                        :items="documentos.data"
                        :show_loading="documentos.isLoading"
                        :total_registros="totalRegistros"
                        v-models:select="paginacion.per_page"
                        v-models:pagina="paginacion.page"
                        @paginar="manejarPaginacionRegistros"
                    >
                        <template v-slot:item.acciones="{ item }">
                            <v-btn @click.stop="visualizarDocumento(item)" :disabled="visualizacionAdjunto.isLoading" icon>
                                <v-icon color="secondary" x-small>
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            {{ formatDate(item.created_at) }}
                        </template>
                        <template v-slot:item.deleted_at="{ item }">
                            <span v-if="item.deleted_at">{{ formatDate(item.deleted_at) }}</span>
                            <b v-else class="text-body-2 font-weight-bold">Último documento cargado</b>
                        </template>
                    </DataTableComponent>
                </div>
            </div>
        </v-dialog>
        <PdfModal
            :isOpen="modalVisualizacionAdjuntoAbierta" 
            :source-loadable="visualizacionAdjunto"
            @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
            :filename="documentoActivo?.nombre ?? 'documento'"
        />
    </Fragment>
</template>
<script>
import { createPageable, createLoadable, setPageableResponse, setLoadableResponse,  toggleLoadable, togglePageable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import PdfModal from '@/components/PdfModal.vue';
import { formatDate } from '@/utils/datetime';
import { Fragment } from 'vue-fragment';

export default {
    name: 'HistorialDocumentosApartado',
    components: { Fragment, DataTableComponent, PdfModal },
    props: {
        apartado: { type: Object, required: true },
        idOficialCumplimiento: { type: String },
    },
    data: () => ({
        documentos: createPageable([], 10),
        documentoActivo: null,
        paginacion: {
            page: 1,
            per_page: 10,
        },
        modalHistorialDocumentosAbierta: false,
        headers: [
            { text: "Nombre", align: "center", value: "nombre", sortable: false },
            { text: "Fecha de carga", align: "center", value: "created_at", sortable: false },
            { text: "Fecha de eliminación", align: "center", value: "deleted_at", sortable: false },
            { text: "Acción", align: "center", value: "acciones", sortable: false },
        ],
        // Visualizacion adjunto
        modalVisualizacionAdjuntoAbierta: false,
        visualizacionAdjunto: createLoadable(null),
    }),
    computed: {
        totalRegistros() {
            return this.documentos.pagination.total_rows;
        },
    },
    methods: {
        formatDate,
        cerrarModal() {
            this.modalHistorialDocumentosAbierta = false;
        },
        async cargarDocumentos() {
            const filtros = {
                pagination: true,
                ...this.paginacion,
            }

            togglePageable(this.documentos);
            const { data, headers } = await this.services.VerificacionDocumentosOdc.cargarHistorialDocumentos(
                this.apartado.id,
                this.idOficialCumplimiento,
                filtros,
            );

            setPageableResponse(this.documentos, data, headers);
        },
        cargarHistorialDocumentos() {
            this.documentos = createPageable([], 10);
            this.paginacion = { ...this.paginacion, page: 1 };
            this.modalHistorialDocumentosAbierta = true;
        },
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginacion = {
                page: pagina,
                per_page: cantidad_por_pagina,
            };
        },
        async visualizarDocumento(documento) {
            this.documentoActivo = documento;
            this.modalVisualizacionAdjuntoAbierta = true;
            toggleLoadable(this.visualizacionAdjunto);
            const { data } = await this.services.VerificacionDocumentosOdc.visualizarDocumentoOficialCumplimiento(documento.id);
            setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
        },
        manejarVisibilidadModalVisualizacionAdjunto(visible) {
            this.modalVisualizacionAdjuntoAbierta = visible;
        },
    },
    watch: {
        paginacion: {
            handler() {
                this.cargarDocumentos();
            },
            deep: true,
        },
    },
}
</script>