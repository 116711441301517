<template>
    <div class="d-flex justify-center align-center" style="gap: 4px;">
        <!-- Indicador documentos cargados -->
        <v-tooltip v-if="arbolCompletamenteCargado" top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" color="green accent-5">
                    {{ apartado.sub_apartados.length > 0 ? 'mdi-order-bool-ascending-variant' : 'mdi-text-box-check' }}
                </v-icon>
            </template>
            <span>
                {{ 
                    apartado.sub_apartados.length > 0
                    ? 'Todos los documentos del apartado han sido cargados'
                    : 'Documento cargado'
                }}
            </span>
        </v-tooltip>
        <v-tooltip v-else top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" color="warning">
                    {{ apartado.sub_apartados.length > 0 ? 'mdi-format-list-checkbox' : 'mdi-file-document-alert' }}
                </v-icon>
            </template>
            <span>
                {{ 
                    apartado.sub_apartados.length > 0
                    ? 'Aún hay documentos pendientes de carga en este apartado'
                    : 'Documento pendiente de carga'
                }}
            </span>
        </v-tooltip>

        <!-- verificacion documentos verificados -->
        <v-tooltip v-if="arbolCompletamenteVerificado" top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" color="green accent-5">mdi-check</v-icon>
            </template>
            <span>
                {{ 
                    apartado.sub_apartados.length > 0
                    ? 'Todos los documentos del apartado han sido verificados'
                    : 'Documento verificado'
                }}
            </span>
        </v-tooltip>
        <v-tooltip v-else top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" color="warning">
                    {{ apartado.verificacion?.estado.id === 2 ? 'mdi-eye-circle' : 'mdi-alert-circle' }}
                </v-icon>
            </template>
            <span>
                {{ 
                    apartado.sub_apartados.length > 0
                    ? 'Aún hay documentos pendientes de verificación en este apartado'
                    : (apartado.verificacion?.estado.id === 2 ? 'Documento con observación' : 'Documento pendiente de verificación')
                }}
            </span>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    name: 'IndicadoresApartadoDocumental',
    props: {
        apartado: { type: Object },
    },
    computed: {
        arbolCompletamenteCargado() {
            return this.verificarArbolCompletamenteCargado(this.apartado);
        },
        arbolCompletamenteVerificado() {
            return this.verificarArbolCompletamenteVerificado(this.apartado);
        },
    },
    methods: {
        verificarArbolCompletamenteCargado(apartadoVerificar) {
            const subApartados = apartadoVerificar.sub_apartados;

            return subApartados.length > 0 ? subApartados.every(this.verificarArbolCompletamenteCargado) : Boolean(apartadoVerificar.documento);
        },
        verificarArbolCompletamenteVerificado(apartadoVerificar) {
            const subApartados = apartadoVerificar.sub_apartados;

            if (subApartados.length > 0) {
                return subApartados.every(this.verificarArbolCompletamenteVerificado);
            }

            return Boolean(apartadoVerificar.verificacion?.estado.id === 3);
        },
    },
}
</script>